import React, { useState } from "react";
import moment from "moment";
import useSWR from "swr";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { format as format$ } from "currency-formatter";
import { parsePhoneNumberFromString } from "libphonenumber-js";
import Layout from "../components/Layout";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import { useParams } from "react-router-dom";
import AddressTable from "../components/tables/AddressTable";
import DepositsTable from "../components/tables/DepositsTable";
import TransfersTable from "../components/tables/TransfersTable";
import { useNavigate } from "react-router-dom";
import LabelledTable from "../components/tables/LabelledTable";
import Card from "../components/Card";
import ReferralTable from "../components/tables/ReferralTable";
import useSelectedRow from "../hooks/useSelectedRow";
import useSelectedRows from "../hooks/useSelectedRows";
import ReferralModal from "../components/modals/ReferralModal";

const UserDetailsPage = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const { data: profileData, error: profileError } = useSWR(
    id && `/user/${id}`
  );
  const { data: depositHints, error: depositHintsError } = useSWR(
    id && `/user/${id}/deposithints`
  );
  const { data: addresses, error: addressesError } = useSWR(
    id && `/user/${id}/address`
  );
  const { data: deposits, error: depositsError } = useSWR(
    id && `/user/${id}/deposit`
  );
  const { data: transfers, error: transfersError } = useSWR(
    id && `/user/${id}/transfer`
  );
  const { data: referrals, error: referralsError } = useSWR(
    id && `/user/${id}/referral`
  );

  const isFetchingProfile = !profileData && !profileError;
  const isFetchingHints = !depositHints && !depositHintsError;
  const isFetchingAddresses = !addresses && !addressesError;
  const isFetchingDeposits = !deposits && !depositsError;
  const isFetchingTransfers = !transfers && !transfersError;
  const isFetchingReferrals = !referrals && !referralsError;

  const {
    userID,
    firstName,
    lastName,
    signUpDate,
    email,
    randomCent,
    idVerificationStatus,
    mobileNumber,
    yob,
    bankID,
    fees,
    mobileVerified,
    emailVerified,
    referredBy
  } = profileData || {};

  const profileColumns = [
    ["User ID", userID],
    ["Name", [firstName, lastName].filter(v => v).join(" ")],
    [
      "Email",
      email,
      {
        trProps: {
          className: !email || emailVerified ? undefined : "table-danger"
        }
      }
    ],
    [
      "Mobile",
      mobileNumber
        ? parsePhoneNumberFromString(mobileNumber, "AU").formatInternational()
        : "",
      {
        trProps: {
          className:
            !mobileNumber || mobileVerified ? undefined : "table-danger"
        }
      }
    ],
    ["Sign Up Date", signUpDate && moment(signUpDate).format("DD-MM-YYYY")],
    [
      "ID Verification Status",
      idVerificationStatus,
      {
        trProps: {
          className:
            idVerificationStatus !== undefined && idVerificationStatus !== 3
              ? "table-danger"
              : undefined
        }
      }
    ],
    ["YOB", yob],
    ["Random Cent", randomCent],
    ["Bank ID", bankID],
    ["Fees", format$(fees, { code: "AUD" })],
    [
      "Referred By",
      <Link
        to={`/user/${referredBy}`}
        target="_blank"
        rel="noopener noreferrer"
      >
        {referredBy}
      </Link>
    ]
  ];

  const payrollColumns = [
    ["Employer", depositHints?.employerName],
    ["Admin Deposit Reference", depositHints?.depositReference],
    ["Deposit Reference", depositHints?.bankStatement],
    ["Deposit Amount", format$(depositHints?.depositAmount, { code: "AUD" })]
  ];

  const [selected, , selectRowConfig] = useSelectedRow(null);
  const [selectedTransfer, dispatch, selectTransferRowConfig] =
    useSelectedRows();

  const selectReferral = referrals && referrals.find(d => d.id === selected);

  const onEditAccountInfoClick = e =>
    navigate(`/user/${id}/accountinfoes/edit`);

  const onEditPayrollClick = e => navigate(`/user/${id}/deposithints/edit`);

  const [isReferralModalOpen, setReferralModalOpen] = useState(false);
  const [transactions, setTransactions] = useState([]);

  return (
    <Layout activeTab="Users">
      <div className="container-fluid py-5" style={{ position: "relative" }}>
        <h1 className="mb-3">User Details</h1>
        <div className="row d-flex">
          <div className="col-xl">
            <div>
              <Card>
                <div className="d-flex justify-content-between">
                  <h2>Profile</h2>
                  <Button className="mb-3" onClick={onEditAccountInfoClick}>
                    <span className="me-2">Edit</span>
                    <ion-icon name="create-outline" />
                  </Button>
                </div>
                <ErrorMessage error={profileError} />
                <Loader loading={isFetchingProfile} />
                <LabelledTable columns={profileColumns} />
              </Card>
            </div>
          </div>
          <div className="col-xl">
            <Card>
              <div className="d-flex justify-content-between">
                <h2>Payroll</h2>
                <Button className="mb-3" onClick={onEditPayrollClick}>
                  <span className="me-2">Edit</span>
                  <ion-icon name="create-outline" />
                </Button>
              </div>
              <ErrorMessage error={depositHintsError} />
              <Loader loading={isFetchingHints} />
              <LabelledTable columns={payrollColumns} />
            </Card>
          </div>
        </div>
        <Card>
          <h2>Addresses</h2>
          <ErrorMessage error={addressesError} />
          <Loader loading={isFetchingAddresses} />
          <AddressTable addresses={addresses} hidden={["userID"]} />
        </Card>
        <Card>
          <h2>Deposits</h2>
          <ErrorMessage error={depositsError} />
          <Loader loading={isFetchingDeposits} />
          <DepositsTable deposits={deposits} hidden={["bank", "userID"]} />
        </Card>
        <Card>
          <h2>Transfers</h2>
          <ErrorMessage error={transfersError} />
          <Loader loading={isFetchingTransfers} />
          <TransfersTable
            transfers={transfers}
            selectRow={selectTransferRowConfig}
            hidden={["email"]}
            options={{
              sizePerPageList: [10, 25, 30, 50],
              sizePerPage: 25
            }}
          />
        </Card>
        <Card>
          <div className="d-flex justify-content-between">
            <h2>Referrals</h2>
            <Button
              className="mb-3"
              onClick={() => setReferralModalOpen(true)}
              disabled={!selected}
            >
              <span className="me-2">Edit</span>
              <ion-icon name="create-outline" />
            </Button>
          </div>
          <ErrorMessage error={referralsError} />
          <Loader loading={isFetchingReferrals} />

          <ReferralTable
            selectRow={selectRowConfig}
            referrals={referrals}
            options={{
              sizePerPageList: [10, 25, 30, 50],
              sizePerPage: 25
            }}
          />
        </Card>

        <ReferralModal
          isOpen={isReferralModalOpen}
          onDismiss={() => setReferralModalOpen(false)}
          initialValues={{ selectReferral }}
        />
      </div>
    </Layout>
  );
};

export default UserDetailsPage;
