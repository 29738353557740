import React, { useEffect } from "react";
import Layout from "../components/Layout";
import useSWR from "swr";
import ErrorMessage from "../components/ErrorMessage";
import Loader from "../components/Loader";
import AddressTable from "../components/tables/AddressTable";
import { ToggleButton } from "../components/ToggleButton";

const AddressPage = () => {
  const { data, error } = useSWR("/address");
  const isLoading = !data && !error;
  const [custodial, setCustodial] = React.useState(false);
  const [addresses, setAddresses] = React.useState([]);

  useEffect(() => {
    let addresses = data;
    if (custodial) {
      addresses = data.filter(address => address.isCustodial);
    }
    setAddresses(addresses);
  }, [custodial, data]);

  return (
    <Layout activeTab="Addresses">
      <div className="container-fluid py-5">
        <Loader loading={isLoading} />
        <div className="d-flex flex-row">
          <div>
            <h1 className="mb-3">Addresses</h1>
          </div>
          <div className="ms-5 d-flex justify-content-between align-items-start mt-2">
            <ToggleButton onChange={setCustodial} defaultChecked={false} />
            <span className="ms-3 mt-1">Custodial Addresses Only</span>
          </div>
        </div>
        <ErrorMessage error={error} />
        <AddressTable addresses={addresses} />
      </div>
    </Layout>
  );
};

export default AddressPage;
