import React from "react";
import useSWR from "swr";
import Loader from "../Loader";
import ErrorMessage from "../ErrorMessage";
import { format as format$ } from "currency-formatter";

const Exchange = () => {
  const { data, error } = useSWR("/ir");
  const isLoading = !data && !error;

  return (
    <div style={{ position: "relative" }}>
      <Loader loading={isLoading} />
      <ErrorMessage error={error} />
      <h2>Exchange</h2>
      <p>AUD: {format$(data?.audBalance, { code: "AUD" })}</p>
      <p>BTC: {data?.btcBalance}</p>
    </div>
  );
};

export default Exchange;
