import React from "react";
import { useNavigate } from "react-router-dom";
import { mutate } from "swr";
import ModalForm from "../ModalForm";
import ReferralSettingForm from "./ReferralSettingForm";

const parseSubmitValues = v => ({
  userID: v.userID,
  referralCode: v.referralCode,
  enabled: JSON.parse(v.enabled)
});
const parseInitialValues = fetchedData =>
  ["referralCode", "userID", "enabled"].reduce((map, item) => {
    if (fetchedData[item]) map[item] = fetchedData[item];
    return map;
  }, {});
const ReferralSettingModel = params => {
  const navigate = useNavigate();
  
  const onDismiss = () => {
    navigate("/referral");
  };

  const onError = (e, formActions) => {
    formActions.setErrors({ hidden: e });
    formActions.setSubmitting(false);
  };

  const onSuccess = async () => {
    mutate("/ReferralSetting");
  };
  const getUrl = id => {
    if (!id) {
      return `/referralSetting`;
    } else {
      return `/referralSetting/${id}`;
    }
  };

  const getFetchUrl = id => `/referralSetting/${id}`;

  return (
    <ModalForm
      parseSubmitValues={parseSubmitValues}
      parseInitialValues={parseInitialValues}
      resource="Referral Setting"
      onSuccess={onSuccess}
      onError={onError}
      onDismiss={onDismiss}
      form={ReferralSettingForm}
      getUrl={getUrl}
      getFetchUrl={getFetchUrl}
    />
  );
};

export default ReferralSettingModel;
